import { ComputedRef, ref, Ref, watch } from "vue";
import { computed } from "@vue/reactivity";
import { IObjectKeys } from "@/interfaces/IObjectKeys";

export interface ISelectOption extends IObjectKeys {
  name?: string;
  title?: string;
  id: number | string;
  selected?: boolean;
  i_product?: number;
}

export interface IUseLocationPackagesOptions {
  parentSelectedOptionId: Ref<number>;
  initialOptions: ComputedRef<ISelectOption[]>;
  onOptionChanged: (options: ISelectOption) => void;
  selectByKeyName?: "id" | "i_product";
}

export interface IUseLocationPackages {
  optionsList: ComputedRef<ISelectOption[]>;
  selectOption: (id: number) => void;
}

export function useLocationPackagesSelect(
  options: IUseLocationPackagesOptions
): IUseLocationPackages {
  const {
    onOptionChanged,
    selectByKeyName = "id",
    initialOptions,
    parentSelectedOptionId
  } = options;

  const selectedId = ref(parentSelectedOptionId.value);

  watch(parentSelectedOptionId, (id: number) => {
    selectedId.value = id;
  });

  const optionsList: ComputedRef<ISelectOption[]> = computed(() => {
    return initialOptions.value.map((option: ISelectOption, idx: number) => {
      return {
        ...option,
        name: option.title || option.name || "",
        selected:
          selectedId.value !== -1
            ? option[selectByKeyName] === selectedId.value
            : idx === 0
      };
    });
  });

  watch(
    optionsList,
    (options: ISelectOption[]) => {
      const selectedOption = options.find(({ selected }) => selected);
      if (selectedOption) {
        onOptionChanged(selectedOption);
      }
    },
    { deep: true, immediate: true }
  );

  return {
    optionsList,
    selectOption(id: number) {
      if (selectByKeyName === "id") {
        selectedId.value = id;
        return;
      }

      selectedId.value =
        optionsList.value.find(option => option.id === id)?.[selectByKeyName] ||
        -1;
    }
  };
}
