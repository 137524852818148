<template>
  <div
    class="input-select"
    :class="`input-select--size-${size} ${useSearch ? 'with-search' : ''}`"
  >
    <AppInput
      :id="id"
      v-model="value"
      :label="label"
      :disabled="disabled"
      input-type="material"
      readonly
      :required="false"
      @click="tooltipShownHandler"
    />

    <TooltipDropdown
      :disabled="disabled"
      :show="tooltipShown"
      :width="listMaxWidth"
      @hide="tooltipHideHandler"
    >
      <template v-if="tooltipShown" #content>
        <div v-if="useSearch" class="searchbar-inner">
          <SearchControl
            :on-search="() => {}"
            name="tooltip-search"
            field-type="inline-field"
            placeholder="search"
            :input-timeout="true"
            @search="searchHandler"
          />
        </div>

        <div class="input-select-list-wrapper">
          <CustomScrollbar :max-height="maxHeight">
            <div
              class="input-select-list"
              :style="`min-width:${listMaxWidth};`"
            >
              <transition-group tag="ul" v-on="transition">
                <li
                  v-for="{ title, name, id, selected } in searchOptions"
                  :key="id"
                  class="input-select-list--item"
                  :class="{ selected }"
                  data-tooltip-option
                  :data-active="selected"
                  @click="selectHandler(id)"
                >
                  <div>
                    <div class="row align-items-center">
                      <div class="col-10">
                        <div class="opt-name">{{ name || title }}</div>
                      </div>

                      <div class="col-1 d-flex justify-content-end">
                        <svg-icon icon="checkmark" />
                      </div>
                    </div>
                  </div>
                </li>
              </transition-group>
            </div>
          </CustomScrollbar>

          <p
            v-if="!searchOptions.length"
            class="text-center pb-5 pt-3 emptyText"
          >
            {{ emptyText }}
          </p>
        </div>
      </template>
    </TooltipDropdown>
  </div>
</template>

<script>
import AppInput from "@/components/Form/AppInput";
import { getId } from "@/utills/getId";
import TooltipDropdown from "@/components/ui/TooltipDropdown";
import SvgIcon from "@/components/ui/SvgIcon";
import { useCustomScrollbar } from "@/mixins/useCustomScrollbar";
import SearchControl from "@/components/Form/SearchControl";
import CustomScrollbar from "@/components/ui/CustomScrollbar";
import { useListHeightTransition } from "@/hooks/animation/useListHeightTransition";
export default {
  name: "SelectInput",
  components: {
    CustomScrollbar,
    SearchControl,
    SvgIcon,
    TooltipDropdown,
    AppInput
  },
  mixins: [useCustomScrollbar],
  emits: ["select"],
  props: {
    emptyKey: { type: String, default: "items" },
    id: { type: String, default: getId() },
    options: { type: Array, required: true },
    fieldValue: { type: String, required: false },
    disabled: { type: Boolean, default: false },
    label: { type: String, required: false },
    size: { type: String, default: "md" },
    useSearch: { type: Boolean, default: false },
    listMaxHeight: { type: String, default: "300px" },
    listMaxWidth: { type: String, default: "300px" }
  },
  data() {
    return {
      tooltipShown: false,
      searchQuery: "",
      transition: { ...useListHeightTransition(0.15) }
    };
  },
  computed: {
    value() {
      const opt = this.options.find(o => o.selected);
      const name = opt?.name || opt?.title;
      return this.fieldValue ?? name ?? "";
    },
    maxHeight() {
      return parseInt(this.listMaxHeight);
    },

    emptyText() {
      return `${this.emptyKey} not found`;
    },

    searchOptions() {
      if (!this.searchQuery.length) {
        return this.options;
      }

      return this.options.filter(({ title = "", name = "" }) => {
        return (
          title.toLowerCase().includes(this.searchQuery) ||
          name.toLowerCase().includes(this.searchQuery)
        );
      });
    }
  },

  methods: {
    searchHandler(query = "") {
      this.searchQuery = query.trim().toLowerCase();
    },
    tooltipShownHandler() {
      this.tooltipShown = !this.tooltipShown;
    },
    tooltipHideHandler() {
      this.tooltipShown = false;
    },
    selectHandler(id) {
      this.$emit("select", id);
      this.tooltipHideHandler();
    }
  }
};
</script>

<style lang="scss">
.input-select {
  $select-list-padding-x: 2em;
  position: relative;
  cursor: pointer;

  .input-select-list-wrapper {
    padding-top: 10px;
    padding-bottom: 10px;

    .simplebar-vertical {
      right: 5px;
      &:before {
        display: none;
      }
    }
  }

  input.input {
    cursor: pointer;
    padding-right: calc(#{$field-padding-x} + 1rem + 15px);
    text-overflow: ellipsis;
  }

  .tooltip-dropdown {
    width: max-content;
    position: absolute;
    top: 50%;
    right: $field-padding-x;
    transform: translate(0, -50%);

    &-inner {
      padding: 0 !important;

      &__content {
        width: max-content;
        min-width: var(--list-width, 300px);
      }
    }

    .tooltip-dropdown-trigger {
      z-index: $underOverlay;
    }
  }
  &-list {
    width: var(--list-width, 300px);
    min-width: 280px;
    max-width: 100%;
    @extend %hideScrollBar;

    .simplebar-track.simplebar-vertical {
      height: calc(100% - 30px);
      top: 15px;
    }

    &--item {
      padding: 1.4em $select-list-padding-x;
      cursor: pointer;

      &:not(:last-child) {
        border-bottom: 1px solid $border-color;
      }

      .icon {
        opacity: 0;
        color: $color-success;
      }

      &.selected {
        font-weight: 700;
        .icon {
          opacity: 1;
        }
      }
    }
    .opt-name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &--size {
    &-sm {
      .input-select-list {
        min-width: 190px;
        padding-top: 15px;
        padding-bottom: 15px;

        &--item {
          padding-top: 0.4em;
          padding-bottom: 0.4em;
          border-bottom: none;
        }
      }
    }
  }

  &.with-search {
    .tooltip-dropdown-inner {
    }

    .input-select-list-wrapper {
      .simplebar-track.simplebar-vertical {
        top: 20px;
      }

      .input-select-list {
        margin-top: 20px;
      }
    }

    .searchbar-inner {
      padding: 30px $select-list-padding-x 0;
    }
    .input-select-list-wrapper {
      padding: 0 $select-list-padding-x !important;
    }
    .input-select-list {
      &--item {
        box-sizing: border-box;
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 1em;
        border: none;
        max-width: 100%;

        & > * {
          padding: 0.6em 0 0.6em 0;
        }

        &:last-child {
          & > * {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

.emptyText {
  &:first-letter {
    text-transform: uppercase;
  }
}
</style>
