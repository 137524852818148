
import SelectInput from "@/components/Form/SelectInput.vue";
import { computed, defineComponent, toRefs } from "vue";
import { IBundle } from "@/api/services/packages/esim/location-packages/types";
import { useStore } from "vuex";
import {
  ISelectOption,
  useLocationPackagesSelect
} from "@/hooks/esim/location-packages/modules/useLocationPackagesSelect";

export default defineComponent({
  name: "SelectBundle",
  components: { SelectInput },

  props: {
    selectedBundleId: { type: Number, default: -1 },
    filterByZone: { type: String, default: "" }
  },
  emits: { select: null },

  setup(props, { emit }) {
    const store = useStore();
    store.dispatch("fetchBundles");

    const { selectedBundleId, filterByZone } = toRefs(props);

    const bundles = computed(() => {
      const bundles = store.getters.bundles;

      if (!filterByZone.value) {
        return bundles;
      }

      if (filterByZone.value === "-1") {
        return [];
      }

      const updatedFilterByZone = filterByZone.value
        .replaceAll(" ", "")
        .toLowerCase();

      return bundles.filter(({ name }: IBundle) => {
        return name
          .replaceAll(" ", "")
          .toLowerCase()
          .includes(updatedFilterByZone);
      });
    });

    const { optionsList, selectOption } = useLocationPackagesSelect({
      parentSelectedOptionId: selectedBundleId,
      initialOptions: bundles,
      selectByKeyName: "i_product",
      onOptionChanged(option: ISelectOption) {
        emit("select", option.i_product);
      }
    });

    return { optionsList, selectOption };
  }
});
